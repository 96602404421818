import React from "react"
import BlogDetail from "../../components/Blog/BlogDetails/Detail"
import Layout from "../../components/Layout"
export default function BlogDetailPage() {
  return (
    <Layout>
      <BlogDetail />
    </Layout>
  )
}
